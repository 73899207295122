main {
  display: grid;
  grid-template-columns: 5fr 4fr;
  grid-template-rows: repeat(4, auto);
  grid-template-areas:
    "work who"
    "work education"
    "work personal-projects"
    "work other-work";
  grid-gap: 1em;
}

main .who-am-i {
  grid-area: who;
}

main .work-experience {
  grid-area: work;
}

main .other-work-experiences {
  grid-area: other-work;
}

main .personal-projects {
  grid-area: personal-projects;
}

main .education {
  grid-area: education;
}

main .awards {
  grid-area: awards;
}
