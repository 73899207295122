.icon {
  color: var(--main-accent-color);
  height: min-content;
  justify-self: center;
}

.tiny {
  font-size: 0.8rem;
}

.small {
  font-size: 1.5rem;
}

.medium {
  font-size: 3rem;
}

.large {
  font-size: 3.5rem;
}
