.work-experience {
  display: grid;
}

.we-content {
  border-left: 1px solid var(--main-light-color);
  padding: 0 0.5em;
  margin: 0.5em 0;
}

.we-content > * {
  margin: 0.3em;
  padding: 0;
}

.we-content > ul {
  margin: 0 2em;
}

.we-content > ul > li {
  list-style-type: circle;
}
